<template>
  <div>
    <b-row class="d-flex justify-content-center align-items-center">
      <div class="form-group col-5">
        <label>Fecha inicio:</label>
        <input
          v-model="form.start"
          :disabled="bLoading"
          type="datetime-local"
          class="form-control form-control-sm"
        />
      </div>
      <div class="form-group col-5">
        <label>Fecha fin:</label>
        <input
          v-model="form.end"
          :disabled="bLoading"
          :min="form.start"
          type="datetime-local"
          class="form-control form-control-sm"
        />
      </div>
      <div class="form-group col-2 mt-7">
        <!-- d-flex justify-content-end mb-3 -->
        <button
          class="btn btn-sm btn-primary btn-circle"
          @click="onGetItems()"
          :disabled="bLoading"
        >
          <i class="fa fa-sync-alt" v-if="!bLoading"></i>
          <span v-else>Cargando...</span>
        </button>
      </div>
    </b-row>
    <b-row>
      <b-col>
        <DxDataGrid
          v-if="items.length"
          :data-source="items"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
        >
          <DxPaging :page-size="15" />
          <DxExport :enabled="true" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true" />
          <DxPager
            :visible="true"
            infoText="Página {0} of {1} ({2} registros)"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxColumn
            v-for="(column, idx) in columns"
            :key="idx"
            :data-field="column.dataField"
            :caption="column.caption"
            :data-type="column.type"
            :alignment="column.alignment"
            :group-index="column.group"
            :format="column.format"
          />
        </DxDataGrid>
        <div v-else>
          <div
            v-if="bLoading"
            class="d-flex justify-content-center aling-items-center"
          >
            <img src="assets/img/loader.gif" alt="Loading..." />
          </div>
          <div v-else class="text-center">
            <div class="text-center">
              <img src="assets/img/empty.png" alt="Empty" />
              <p class="text-muted">No hay información para mostrar</p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxExport,
  DxGroupPanel,
} from "devextreme-vue/data-grid";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
  },
  data() {
    return {
      columns: [],
      items: [],
      bLoading: false,
      form: {
        start: moment().format("YYYY-MM-01T06:00:00"),
        end: moment().format("YYYY-MM-DDT18:00:00"),
      },
    };
  },
  mounted() {
    this.onGetItems();
  },
  methods: {
    async onGetItems() {
      try {
        this.bLoading = true;
        const {
          data: { columns, items },
        } = await axios.post("/log-eventos", {
          date_start: moment(this.form.start).format("YYYY-MM-DD HH:mm:ss"),
          date_end: moment(this.form.end).format("YYYY-MM-DD HH:mm:ss"),
        });
        this.columns = columns;
        this.items = items;
        this.bLoading = false;
      } catch (error) {
        error;
      }
    },
  },
};
</script>
